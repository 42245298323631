@import './../../../../vars.scss';

.all-in-one {
  height: 100%;
  .container-getpaid {
    height: 100%;
    display: grid;
    grid-template-columns: 40% 60%;
  }

  .container-info {
    margin: auto 0;
    .title {
      font-size: 1.5em;
      line-height: 1.3;
      margin-right: 1em;
    }
  }

  .content-imageGetpaid {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
  }

  @media (max-width: $md) and (orientation: portrait) {
    .content-imageGetpaid {
      background-size: cover;
      background-position: 0% 50%;
    }
    .container-getpaid {
      grid-template-columns: 55% 45%;
    }
  }
  
  @media (max-width: $md) and (orientation: landscape) {
    .content-imageGetpaid {
      background-size: cover;
      background-position: 0% 50%;
    }
    .container-getpaid {
      grid-template-columns: 55% 45%;
    }
  }
}