

.instant-deposits {
  .container-getpaid {
    display: grid;
    grid-template-columns: 40% 60%;
  }

  .container-info {
    margin: auto 0;
    .title {
      font-size: 1.5em;
      line-height: 1.3;
      margin-right: 1em;
    }
  }

  .content-imageGetpaid {
    -webkit-box-shadow: inset 0px -104px 38px -61px rgba(255,255,255,1);
    -moz-box-shadow: inset 0px -104px 38px -61px rgba(255,255,255,1);
    box-shadow: inset 0px -104px 38px -61px rgba(255,255,255,1);
    .image-app {
      max-width: 100%;
    }
  }
}