@import './../../../vars.scss';

.content-steps {
  padding: 5em 0;
  .title {
    font-size: 2em;
  }

  .appbar-steps {
    background-color: white;
    box-shadow: none;
    color: $blue-light-beluga;
    font-weight: 300;

    .MuiTabs-indicator {
      background-color: #33e2d9;
      transition: all 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
    .MuiTabs-flexContainer {
      justify-content: space-around;
    }
    .MuiTab-fullWidth {
      max-width: fit-content;
    }
  }
  .container-page {
    max-height: auto;
    padding: 5em 0 0 0;
  }

  .text-focusTechnology {
    margin: 8em 0 4em 0px;
  }

  .containerSwipeableViews {
    & > div > div > div, & > div > div > div .container-page {
      height: 100%;
    }
  }
}

.big-font {
  font-size: 1.4em !important;
}