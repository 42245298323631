@import './../../../vars.scss';


.content-demo {
  position: relative;
  padding: 0 0 10em 0;
  .container-info {
    display: grid;
    grid-template-columns: 55% 45%;
    min-height: calc(100vh + 560px);
    align-items: center;
  }

  .container-devices {
    background-position: 90% 0%, 100% 0%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
  }

  @media (max-width: $xl) and (orientation: portrait)  {
    .container-info {
      min-height: calc(100vh + 234px);
    }
  }
  
  @media (max-width: $xl) and (orientation: landscape)  {
    .container-info {
      min-height: calc(100vh + 234px);
    }
  }

  @media (max-width: $lg) and (orientation: portrait)  {
    .container-info {
      min-height: 750px;
    }
  }
  
  @media (max-width: $lg) and (orientation: landscape)  {
    .container-info {
      min-height: 750px;
    }
  }

  @media (max-width: $md) and (orientation: portrait)  {
    .container-info {
      min-height: 640px;
    }
    .container-devices {
      background-position: 90% 10%, 100% 0%;
    }
  }
  
  @media (max-width: $md) and (orientation: landscape)  {
    .container-info {
      min-height: 640px;
    }
    .container-devices {
      background-position: 90% 10%, 100% 0%;
    }
  }

  @media (max-width: $sm) and (orientation: portrait)  {
    .container-info {
      min-height: 530px;
    }
    .container-devices {
      background-position: 60% 10%, 100% 0%;
      background-size: 132%;
    }
  }
  
  @media (max-width: $sm) and (orientation: landscape)  {
    .container-info {
      min-height: 530px;
    }
    .container-devices {
      background-position: 60% 10%, 100% 0%;
      background-size: 132%;
    }
  }

  .img-devices {
    width: 100%;
  }

  .container-info-right {
    place-self: center;
  }

  .container-info-services {
    .img-clock {
      width: 6.5em;
    }
    .text-services {
      color: #364444;
      font-size: 1.7em;
      font-weight: 200;
    }
    .btn-demo {
      font-size: 1.3em;
      font-weight: 200;
      padding: 0.3em 4em;
      border-radius: 10px;
      transition: all 250ms;

      box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 8px 0px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 3px 3px -2px;
      background-color: $blue-light-beluga !important;
      border: 1px solid $blue-light-beluga !important;
      color: white;

      &:hover {
        background-color: white !important;
        color: $blue-light-beluga;
        border: 1px solid $skyblue-light-beluga !important;
      }
    }
  }
}

@media ( max-width: $md ) { 
  .content-demo {
    padding-bottom: 0;
    .container-info {
      grid-template-columns: 100%;
      & > div:nth-child(1) {
        order: 2;
        height: 100%;
      }
      .container-info-services {
        font-size: 12px;
      }
    }
  } 
}