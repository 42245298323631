@import './../../../vars.scss';

.content-trademark {
  margin: 6em 0px !important;
  .item-carrusel-trademark img {
    height: 2.8em;
  }

  @media (max-width: $sm) {
    .item-carrusel-trademark img {
      height: 5em;
    }
  }
}