@import url(https://fonts.googleapis.com/css?family=Poppins:100,300,400,500,700&display=swap);
body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: 'Poppins', sans-serif;
  -moz-osx-font-smoothing: 'Poppins', sans-serif; }

.w-100 {
  width: 100%; }

@media (min-width: 0px) {
  html, body {
    font-size: 9px; } }

@media (min-width: 600px) {
  html, body {
    font-size: 13px; } }

@media (min-width: 960px) {
  html, body {
    font-size: 16px; } }

@media (min-width: 1280px) {
  html, body {
    font-size: 16px; } }

@media (min-width: 1920px) {
  html, body {
    font-size: 20px; } }

.back-blue-dark-beluga {
  background-color: #00373f !important; }

.text-blue-dark-beluga {
  color: #00373f !important; }

.text-blue-light-beluga {
  color: #06a0b5 !important; }

.text-white {
  color: white; }

.light {
  font-weight: 200 !important; }

.normal {
  font-weight: 400 !important; }

.semi-bold {
  font-weight: 500 !important; }

.bold {
  font-weight: 700 !important; }

.text-center {
  text-align: center; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.position-relative {
  position: relative; }

.btn {
  display: inline-block;
  padding: .3em 1.1em;
  border-radius: 10px;
  text-transform: uppercase;
  font-weight: 100;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px 0px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 2px 1px -1px;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.btn:hover {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 8px 0px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 3px 3px -2px; }

.btn-blue-dark {
  background-color: #00373f;
  color: white;
  -webkit-transition: all 250ms !important;
  transition: all 250ms !important; }
  .btn-blue-dark.btn-blue-dark:hover {
    background-color: white;
    color: #00373f; }

.btn-header {
  font-size: 1.5em; }

.container-slider-menu .MuiAppBar-colorPrimary {
  background-color: white !important; }
  .container-slider-menu .MuiAppBar-colorPrimary .MuiToolbar-root {
    padding: 15px 0 20px 0;
    min-height: auto !important; }

.container-slider-menu .MuiPaper-elevation4 {
  box-shadow: 0px -20px 4px -1px rgba(0, 0, 0, 0.2), 0px -20px 5px 0px rgba(0, 0, 0, 0.14), 1px -1px 10px 0px rgba(0, 0, 0, 0.12) !important; }

.container-slider-menu .image-logo {
  height: 3.1em; }

.container-menu {
  color: #00373f;
  display: flex;
  width: 100%;
  justify-content: space-between; }
  .container-menu .container-logo {
    height: 3.1em; }
  .container-menu .container-bts {
    display: flex;
    align-items: baseline;
    padding-top: 6px; }
    .container-menu .container-bts .btn-menu {
      font-weight: 200;
      margin-right: 2em; }
      .container-menu .container-bts .btn-menu:last-child {
        margin-right: 0; }
      .container-menu .container-bts .btn-menu .MuiButton-root {
        font-size: inherit;
        font-weight: inherit;
        font-family: inherit;
        text-transform: inherit; }
  .container-menu .container-btn-flags .icon-flag {
    width: 1.7em; }
  .container-menu.mobile {
    font-size: 16px; }
    .container-menu.mobile .container-bts {
      display: block; }
      .container-menu.mobile .container-bts .btn-menu {
        margin: 0; }
        .container-menu.mobile .container-bts .btn-menu > .MuiButton-root {
          width: 100%; }
    .container-menu.mobile .container-btn-flags {
      text-align: center; }
      .container-menu.mobile .container-btn-flags .icon-flag {
        width: 3.7em; }

.btn-icon-menu {
  background-color: white !important;
  position: fixed !important;
  right: 5%;
  top: 5%;
  z-index: 10;
  width: 4em !important;
  height: 4em !important;
  min-height: auto !important;
  min-width: auto !important;
  padding: .7em !important;
  box-sizing: content-box !important; }

.icon-show-menu {
  width: 100%; }

@media (max-width: 1024px) {
  .container-menu {
    display: block;
    padding: .5em; } }

@media (max-width: 600px) {
  .btn-icon-menu {
    top: 3%;
    width: 3em;
    height: 3em; } }

.expand-link {
  display: inline-block; }
  .expand-link .flag-color {
    display: none; }
  .expand-link:hover .flag-black,
  .expand-link:active .flag-black {
    display: none; }
  .expand-link:hover .flag-color,
  .expand-link:active .flag-color {
    display: block; }

a:visited, a:link {
  text-decoration: none; }

.container-calendly {
  display: flex;
  align-items: center;
  justify-content: center; }

.iconLoading {
  position: absolute !important; }

.section-background {
  height: 100vh;
  width: 100vw;
  background-position: 80% 0%;
  background-size: cover;
  display: flex;
  align-items: center; }
  .section-background .section-text-left {
    font-weight: bold;
    text-transform: uppercase;
    color: white;
    font-size: 5.3em;
    line-height: 1em;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }

@media (max-width: 600px) {
  .section-background {
    align-items: normal;
    max-height: 550px; }
    .section-background .section-text-left {
      margin-top: 1.5em; } }

.content-trademark {
  margin: 6em 0px !important; }
  .content-trademark .item-carrusel-trademark img {
    height: 2.8em; }
  @media (max-width: 600px) {
    .content-trademark .item-carrusel-trademark img {
      height: 5em; } }

.content-particles > div {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 0; }

.content-demo {
  position: relative;
  padding: 0 0 10em 0; }
  .content-demo .container-info {
    display: grid;
    grid-template-columns: 55% 45%;
    min-height: calc(100vh + 560px);
    align-items: center; }
  .content-demo .container-devices {
    background-position: 90% 0%, 100% 0%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%; }
  @media (max-width: 1920px) and (orientation: portrait) {
    .content-demo .container-info {
      min-height: calc(100vh + 234px); } }
  @media (max-width: 1920px) and (orientation: landscape) {
    .content-demo .container-info {
      min-height: calc(100vh + 234px); } }
  @media (max-width: 1280px) and (orientation: portrait) {
    .content-demo .container-info {
      min-height: 750px; } }
  @media (max-width: 1280px) and (orientation: landscape) {
    .content-demo .container-info {
      min-height: 750px; } }
  @media (max-width: 960px) and (orientation: portrait) {
    .content-demo .container-info {
      min-height: 640px; }
    .content-demo .container-devices {
      background-position: 90% 10%, 100% 0%; } }
  @media (max-width: 960px) and (orientation: landscape) {
    .content-demo .container-info {
      min-height: 640px; }
    .content-demo .container-devices {
      background-position: 90% 10%, 100% 0%; } }
  @media (max-width: 600px) and (orientation: portrait) {
    .content-demo .container-info {
      min-height: 530px; }
    .content-demo .container-devices {
      background-position: 60% 10%, 100% 0%;
      background-size: 132%; } }
  @media (max-width: 600px) and (orientation: landscape) {
    .content-demo .container-info {
      min-height: 530px; }
    .content-demo .container-devices {
      background-position: 60% 10%, 100% 0%;
      background-size: 132%; } }
  .content-demo .img-devices {
    width: 100%; }
  .content-demo .container-info-right {
    place-self: center; }
  .content-demo .container-info-services .img-clock {
    width: 6.5em; }
  .content-demo .container-info-services .text-services {
    color: #364444;
    font-size: 1.7em;
    font-weight: 200; }
  .content-demo .container-info-services .btn-demo {
    font-size: 1.3em;
    font-weight: 200;
    padding: 0.3em 4em;
    border-radius: 10px;
    -webkit-transition: all 250ms;
    transition: all 250ms;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 8px 0px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 3px 3px -2px;
    background-color: #06a0b5 !important;
    border: 1px solid #06a0b5 !important;
    color: white; }
    .content-demo .container-info-services .btn-demo:hover {
      background-color: white !important;
      color: #06a0b5;
      border: 1px solid #1ffff9 !important; }

@media (max-width: 960px) {
  .content-demo {
    padding-bottom: 0; }
    .content-demo .container-info {
      grid-template-columns: 100%; }
      .content-demo .container-info > div:nth-child(1) {
        order: 2;
        height: 100%; }
      .content-demo .container-info .container-info-services {
        font-size: 12px; } }

.content-what-we-do {
  color: white; }
  .content-what-we-do .gradiente-blue-green {
    background: #00c0fa;
    background: -webkit-gradient(left top, left bottom, color-stop(0%, #00c0fa), color-stop(69%, #1fcce3), color-stop(100%, #80ff9f));
    background: -webkit-gradient(linear, left top, left bottom, from(#00c0fa), color-stop(69%, #1fcce3), to(#80ff9f));
    background: linear-gradient(to bottom, #00c0fa 0%, #1fcce3 69%, #80ff9f 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00c0fa', endColorstr='#80ff9f', GradientType=0 );
    padding: 10em 0; }
    @media (max-width: 960px) {
      .content-what-we-do .gradiente-blue-green > div {
        font-size: 13px; } }
  .content-what-we-do .title-section {
    font-size: 1.8em; }
  .content-what-we-do .icon-world {
    width: 4.5em; }
  .content-what-we-do .container-text-banking {
    font-size: 1.6em;
    line-height: 1.4; }
  .content-what-we-do .container-text-subtitle {
    font-size: 1em;
    line-height: 1.4; }

.instant-deposits .container-getpaid {
  display: grid;
  grid-template-columns: 40% 60%; }

.instant-deposits .container-info {
  margin: auto 0; }
  .instant-deposits .container-info .title {
    font-size: 1.5em;
    line-height: 1.3;
    margin-right: 1em; }

.instant-deposits .content-imageGetpaid {
  box-shadow: inset 0px -104px 38px -61px white; }
  .instant-deposits .content-imageGetpaid .image-app {
    max-width: 100%; }

.instant-payment {
  height: 100%; }
  .instant-payment .container-getpaid {
    height: 100%;
    display: grid;
    grid-template-columns: 40% 60%; }
  .instant-payment .container-info {
    margin: auto 0; }
    .instant-payment .container-info .title {
      font-size: 1.5em;
      line-height: 1.3;
      margin-right: 1em; }
  .instant-payment .content-imageGetpaid {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain; }
  @media (max-width: 960px) and (orientation: portrait) {
    .instant-payment .content-imageGetpaid {
      background-size: cover;
      background-position: 0% 50%; }
    .instant-payment .container-getpaid {
      grid-template-columns: 55% 45%; } }
  @media (max-width: 960px) and (orientation: landscape) {
    .instant-payment .content-imageGetpaid {
      background-size: cover;
      background-position: 0% 50%; }
    .instant-payment .container-getpaid {
      grid-template-columns: 55% 45%; } }

.all-in-one {
  height: 100%; }
  .all-in-one .container-getpaid {
    height: 100%;
    display: grid;
    grid-template-columns: 40% 60%; }
  .all-in-one .container-info {
    margin: auto 0; }
    .all-in-one .container-info .title {
      font-size: 1.5em;
      line-height: 1.3;
      margin-right: 1em; }
  .all-in-one .content-imageGetpaid {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain; }
  @media (max-width: 960px) and (orientation: portrait) {
    .all-in-one .content-imageGetpaid {
      background-size: cover;
      background-position: 0% 50%; }
    .all-in-one .container-getpaid {
      grid-template-columns: 55% 45%; } }
  @media (max-width: 960px) and (orientation: landscape) {
    .all-in-one .content-imageGetpaid {
      background-size: cover;
      background-position: 0% 50%; }
    .all-in-one .container-getpaid {
      grid-template-columns: 55% 45%; } }

.content-steps {
  padding: 5em 0; }
  .content-steps .title {
    font-size: 2em; }
  .content-steps .appbar-steps {
    background-color: white;
    box-shadow: none;
    color: #06a0b5;
    font-weight: 300; }
    .content-steps .appbar-steps .MuiTabs-indicator {
      background-color: #33e2d9;
      -webkit-transition: all 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      transition: all 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; }
    .content-steps .appbar-steps .MuiTabs-flexContainer {
      justify-content: space-around; }
    .content-steps .appbar-steps .MuiTab-fullWidth {
      max-width: -webkit-fit-content;
      max-width: -moz-fit-content;
      max-width: fit-content; }
  .content-steps .container-page {
    max-height: auto;
    padding: 5em 0 0 0; }
  .content-steps .text-focusTechnology {
    margin: 8em 0 4em 0px; }
  .content-steps .containerSwipeableViews > div > div > div, .content-steps .containerSwipeableViews > div > div > div .container-page {
    height: 100%; }

.big-font {
  font-size: 1.4em !important; }

.section-between {
  background-repeat: no-repeat;
  background-size: 150%;
  background-position: 50% 95%;
  padding-bottom: 4em;
  margin-bottom: -4em;
  z-index: 2;
  position: relative; }

.section-download {
  padding-top: 5em;
  min-height: 911px;
  display: flex;
  align-items: center; }
  .section-download .btn-account {
    color: white;
    font-size: 1.3rem;
    font-weight: 200;
    border: 1px solid #1ffff9 !important;
    padding: 0.3rem 2rem;
    border-radius: 10px;
    -webkit-transition: all 250ms;
    transition: all 250ms; }
  .section-download .btn-account:hover {
    border: 1px solid white !important;
    background-color: white;
    color: #06a0b5; }
  .section-download .text-important {
    font-size: 2em; }
  .section-download .subtitle-contact {
    font-size: 1.7em; }
  .section-download .container-btn-apps {
    display: flex;
    align-items: center;
    justify-content: center; }
    .section-download .container-btn-apps .icon-app {
      width: 8em;
      margin-right: 2em; }

.anchor {
  text-decoration: none; }
  .anchor:link .anchor:visited {
    text-decoration: none; }

.container-info-download {
  margin: auto; }

@media (max-width: 1920px) and (orientation: portrait) {
  .section-download {
    min-height: 900px; } }

@media (max-width: 1920px) and (orientation: landscape) {
  .section-between {
    padding-bottom: 6em;
    margin-bottom: -6em; }
  .section-download {
    min-height: 900px; }
  .container-info-download {
    margin-top: 6em; } }

@media (max-width: 1440px) and (orientation: landscape) {
  .section-between {
    padding-bottom: 6em;
    margin-bottom: -6em; }
  .section-download {
    min-height: 675px; } }

@media (max-width: 1280px) and (orientation: portrait) {
  .section-download {
    min-height: 500px; }
  .container-info-download {
    margin-top: 3em; } }

@media (max-width: 1280px) and (orientation: landscape) {
  .section-download {
    min-height: 500px; }
  .container-info-download {
    margin-top: 0em; } }

@media (max-width: 960px) and (orientation: portrait) {
  .section-download {
    min-height: 375px; }
  .section-between {
    padding-bottom: 6em;
    margin-bottom: -6em; }
  .container-info-download {
    margin-top: 3em; } }

@media (max-width: 960px) and (orientation: landscape) {
  .section-download {
    min-height: 375px; }
  .section-between {
    padding-bottom: 7em;
    margin-bottom: -7em; }
  .container-info-download {
    margin-top: 3em; } }

@media (max-width: 600px) and (orientation: portrait) {
  .section-download {
    min-height: 190px; }
  .section-between {
    padding-bottom: 3em;
    margin-bottom: -3em; }
  .container-info-download {
    margin-top: 0em; } }

@media (max-width: 600px) and (orientation: landscape) {
  .section-download {
    min-height: 190px; }
  .container-info-download {
    margin-top: 0em; } }

.footer {
  border-top: solid 1px #00aad1;
  color: white;
  margin-top: 1px;
  padding: 1.2em 0;
  font-size: .8em; }
  .footer-vert-gap {
    margin: 50px 0 30px; }
  .footer-mail {
    color: white;
    font-weight: 200;
    font-size: 1.3em; }
  .footer-follow {
    color: #06a0b5;
    font-weight: 300;
    font-size: 1.5em; }
  .footer-social {
    width: 2em;
    height: 2em;
    margin: 5px 15px 0 0; }

footer {
  padding-top: 3em;
  background-color: #116f7c; }
  footer a {
    color: white; }
  footer .link {
    font-style: inherit;
    color: inherit;
    font-size: inherit; }
  footer .container-footer-info {
    text-align: center; }

.image-logo {
  width: 10em; }

.center {
  text-align: center; }

#txt-white {
  color: white;
  font-weight: 200; }

.list {
  margin: .5em 0 .3em 0 !important;
  padding-left: .7em !important; }
  .list-head {
    border-left: solid #06a0b5 2px; }

@media only screen and (min-width: 435px) {
  .center {
    text-align: left; } }

@media only screen and (max-width: 768px) {
  .footer-mail {
    padding: 10px 0;
    color: white;
    font-weight: 200;
    font-size: 1.5em; }
  .footer-social {
    width: 2.3em;
    height: 2.3em; } }

.container-contact .container-form {
  z-index: 3;
  position: relative;
  background-color: #116f7c;
  max-width: 1048px;
  width: calc(100% - 4em);
  margin: auto;
  border-radius: 20px 20px 0 0;
  padding: 2em;
  box-shadow: -14px -14px 19px -9px rgba(70, 70, 70, 0.3), 14px -14px 19px -9px rgba(70, 70, 70, 0.3); }

.container-contact .container-contact-info {
  display: grid;
  grid-template-columns: calc(50% - 3em) 50%;
  grid-column-gap: 3em; }
  .container-contact .container-contact-info .MuiInput-underline:after {
    border-bottom: 2px solid #00aad1; }
  .container-contact .container-contact-info .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 2px solid #00aad1; }
  .container-contact .container-contact-info .MuiInput-underline:before {
    border-bottom: 1px solid #00aad1; }
  .container-contact .container-contact-info .MuiFormLabel-root, .container-contact .container-contact-info .MuiFormLabel-root.Mui-focused {
    color: white;
    font-weight: 300; }
  .container-contact .container-contact-info .MuiInputBase-input {
    padding: 6px 0 15px; }
  .container-contact .container-contact-info input, .container-contact .container-contact-info textarea {
    color: white;
    font-weight: 300; }
  .container-contact .container-contact-info .title-contact {
    color: #1ffff9;
    text-align: left;
    font-size: 1.5em; }
  .container-contact .container-contact-info .talkUs {
    font-weight: 200;
    font-size: 1.9em;
    letter-spacing: 2px;
    line-height: 1em;
    color: white; }
  .container-contact .container-contact-info .center {
    text-align: left;
    padding-top: 14em; }
  .container-contact .container-contact-info .btn-send {
    color: white;
    font-size: 1.3em;
    font-weight: 100;
    border: 1px solid #1ffff9 !important;
    padding: 0.1em 2.3em;
    border-radius: 10px;
    -webkit-transition: all 250ms;
    transition: all 250ms; }
  .container-contact .container-contact-info .btn-send:hover {
    border: 1px solid white !important;
    background-color: white;
    color: #06a0b5; }

.container-contact .lower {
  text-transform: lowercase !important; }

.container-contact .container-footer {
  padding-top: 26em;
  margin-top: -26em;
  background-color: #116f7c;
  z-index: 1;
  position: relative; }

.MuiSnackbar-root .MuiTypography-root {
  background-color: white;
  color: #00373f; }

@media only screen and (min-width: 426px) {
  .container-contact .container-contact-info .center {
    padding-top: 11em; } }

@media only screen and (min-width: 1024px) {
  .container-contact .container-contact-info .center {
    padding-top: 9.5em; } }

@media only screen and (min-width: 1920px) {
  .container-contact .container-contact-info .center {
    padding-top: 8em; } }

.container-terms-conditions .terms-conditions {
  margin: 6em 0; }

