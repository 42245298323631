@import './../../../vars.scss';

.section-background {
  height: 100vh;
  width: 100vw;
  background-position: 80% 0%;
  background-size: cover;
  display: flex;
  align-items: center;
  
  .section-text-left {
    font-weight: bold;
    text-transform: uppercase;
    color: white;
    font-size: 5.3em;
    line-height: 1em;
    -webkit-touch-callout: none;
      -webkit-user-select: none;
       -khtml-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;
  }
}

@media (max-width: $sm) {
  .section-background {
    align-items: normal;
    max-height: 550px;
    .section-text-left {
      margin-top: 1.5em;
    }
  }
}

