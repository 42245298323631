@import './../../../vars.scss';

.content-what-we-do {
  color: white;
  .gradiente-blue-green {
    background: rgba(0,192,250,1);
    background: -moz-linear-gradient(top, rgba(0,192,250,1) 0%, rgba(31,204,227,1) 69%, rgba(128,255,159,1) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0,192,250,1)), color-stop(69%, rgba(31,204,227,1)), color-stop(100%, rgba(128,255,159,1)));
    background: -webkit-linear-gradient(top, rgba(0,192,250,1) 0%, rgba(31,204,227,1) 69%, rgba(128,255,159,1) 100%);
    background: -o-linear-gradient(top, rgba(0,192,250,1) 0%, rgba(31,204,227,1) 69%, rgba(128,255,159,1) 100%);
    background: -ms-linear-gradient(top, rgba(0,192,250,1) 0%, rgba(31,204,227,1) 69%, rgba(128,255,159,1) 100%);
    background: linear-gradient(to bottom, rgba(0,192,250,1) 0%, rgba(31,204,227,1) 69%, rgba(128,255,159,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00c0fa', endColorstr='#80ff9f', GradientType=0 );
    padding: 10em 0;

    @media ( max-width: $md ) { 
      & > div {
        font-size: 13px;
      }
    }
  }

  .title-section {
    font-size: 1.8em;
  }

  .icon-world {
    width: 4.5em;
  }

  .container-text-banking {
    font-size: 1.6em;
    line-height: 1.4;
  }

  .container-text-subtitle {
    font-size: 1em;
    line-height: 1.4;
  }
}