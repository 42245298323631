@import url('https://fonts.googleapis.com/css?family=Poppins:100,300,400,500,700&display=swap');
@import './vars.scss';

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: 'Poppins', sans-serif;
  -moz-osx-font-smoothing: 'Poppins', sans-serif;
}

.w-100 {
  width: 100%;
}

@media (min-width: $xs) {
  html, body { 
    font-size: 9px; 
  }
}

@media (min-width: $sm) {
  html, body { 
    font-size: 13px; 
  }
}

@media (min-width: $md) {
  html, body { 
    font-size: 16px; 
  }
}

@media (min-width: $lg) {
  html, body { 
    font-size: 16px; 
  }
}

@media (min-width: $xl) {
  html, body { 
    font-size: 20px; 
  }
}