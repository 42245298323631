@import './../../../vars.scss';

.section-between {
  background-repeat: no-repeat;
  background-size: 150%;
  background-position: 50% 95%;
  padding-bottom: 4em;
  margin-bottom: -4em;
  z-index: 2;
  position: relative;
}

.section-download {
  padding-top: 5em;
  min-height: 911px;
  display: flex;
  align-items: center;

  .btn-account {
    color: white;
    font-size: 1.3rem;
    font-weight: 200;
    border: 1px solid #1ffff9 !important;
    padding: 0.3rem 2rem;
    border-radius: 10px;
    transition: all 250ms;  
  }

  .btn-account:hover {
    border: 1px solid white !important;
    background-color: white;
    color: #06a0b5;
  }

  .text-important {
    font-size: 2em;
  }

  .subtitle-contact {
    font-size: 1.7em;
  }

  .container-btn-apps {
    display: flex;
    align-items: center;
    justify-content: center;
    .icon-app {
      width: 8em;
      margin-right: 2em;
    }
  }
}

.anchor {
  text-decoration: none;

  &:link
  &:visited {
    text-decoration: none;
  }
}

.container-info-download {
  margin: auto;
}

@media (max-width: $xl) and (orientation: portrait) {
  .section-download {
    min-height: 900px;
  }
}

@media (max-width: $xl) and (orientation: landscape) {
  .section-between {
    padding-bottom: 6em;
    margin-bottom: -6em;
  }
  .section-download {
    min-height: 900px;
  }
  .container-info-download {
    margin-top: 6em;
  }
}

@media (max-width: $ml) and (orientation: landscape) {
  .section-between {
    padding-bottom: 6em;
    margin-bottom: -6em;
  }
  .section-download {
    min-height: 675px;
  }
}

@media (max-width: $lg) and (orientation: portrait) {
  .section-download {
    min-height: 500px;
  }
  .container-info-download {
    margin-top: 3em;
  }
}

@media (max-width: $lg) and (orientation: landscape) {
  .section-download {
    min-height: 500px;
  }
  .container-info-download {
    margin-top: 0em;
  }
}

@media (max-width: $md) and (orientation: portrait) {
  .section-download {
    min-height: 375px;
  }
  .section-between {
    padding-bottom: 6em;
    margin-bottom: -6em;
  }
  .container-info-download {
    margin-top: 3em;
  }
}

@media (max-width: $md) and (orientation: landscape) {
  .section-download {
    min-height: 375px;
  }
  .section-between {
    padding-bottom: 7em;
    margin-bottom: -7em;
  }
  .container-info-download {
    margin-top: 3em;
  }
}

@media (max-width: $sm) and (orientation: portrait) {
  .section-download {
    min-height: 190px;
  }
  .section-between {
    padding-bottom: 3em;
    margin-bottom: -3em;
  }
  .container-info-download {
    margin-top: 0em;
  }
}

@media (max-width: $sm) and (orientation: landscape) {
  .section-download {
    min-height: 190px;
  }
  .container-info-download {
    margin-top: 0em;
  }
}