@import './../../../vars.scss';

.container-slider-menu {
  .MuiAppBar-colorPrimary {
    background-color: white !important;
    .MuiToolbar-root {
      padding: 15px 0 20px 0;
      min-height: auto !important;
    }
  }
  
  .MuiPaper-elevation4 {
    box-shadow:
      0px -20px 4px -1px rgba(0,0,0,0.2),
      0px -20px 5px 0px rgba(0,0,0,0.14),
      1px -1px 10px 0px rgba(0,0,0,0.12) !important;
  }
  
  .image-logo {
    height: 3.1em;
  }
}

.container-menu {
  color: $blue-dark-beluga;
  display: flex;
  width: 100%;
  justify-content: space-between;

  .container-logo {
    height: 3.1em;
  }

  .container-bts {
    display: flex;
    align-items: baseline;
    padding-top: 6px;  

    .btn-menu {
      font-weight: 200;
      margin-right: 2em;
      &:last-child {
        margin-right: 0;
      }
      .MuiButton-root {
        font-size: inherit;
        font-weight: inherit;
        font-family: inherit;
        text-transform: inherit;
      }
    }
  }

  .container-btn-flags {
    .icon-flag {
      width: 1.7em;
    }
  }

  &.mobile {
    font-size: 16px;
    .container-bts {
      display: block;
      .btn-menu {
        margin: 0;
        & > .MuiButton-root {
          width: 100%;
        }
      }
    }
    .container-btn-flags {
      text-align: center;
      .icon-flag {
        width: 3.7em;
      }
    }
  }
}

.btn-icon-menu {
  background-color: white !important;
  position: fixed !important;
  right: 5%;
  top: 5%;
  z-index: 10;
  width: 4em !important;
  height: 4em !important;
  min-height: auto !important;
  min-width: auto !important;
  padding: .7em !important;
  box-sizing: content-box !important;
}
.icon-show-menu {
  width: 100%;
}

@media (max-width: 1024px) {
  .container-menu {
    display: block;
    padding: .5em;
  }
}

@media (max-width: $sm) {
  .btn-icon-menu {
    top: 3%;
    width: 3em;
    height: 3em;
  }
}