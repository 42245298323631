$xl: 1920px;
$ml: 1440px;
$lg: 1280px;
$md: 960px;
$sm: 600px;
$xs: 0px;


$blue-dark-beluga: #00373f;
$blue-light-beluga: #06a0b5;
$skyblue-light-beluga: #1ffff9;
$back-contact: #116f7c;
$line-form: #00aad1;
$title-contact: #1ffff9;