@import './../../../vars.scss';

.footer {
  border-top: solid 1px $line-form;
  color: white;
  margin-top: 1px;
  padding: 1.2em 0;
  font-size: .8em;


  &-vert-gap {
    margin: 50px 0 30px;
  }

  &-mail {
    color: white;
    font-weight: 200;
    font-size: 1.3em;
  }

  &-follow {
    color: #06a0b5;
    font-weight: 300;
    font-size: 1.5em;
  }

  &-social {
    width: 2em;
    height: 2em;
    margin: 5px 15px 0 0;
  }
}

footer {
  padding-top: 3em;
  background-color: $back-contact;

  a {
    color: white;
  }
  
  .link {
    font-style: inherit;
    color: inherit;
    font-size: inherit;
  }

  .container-footer-info {
    text-align: center;
  }
}

.image-logo {
  width: 10em;
}

.center { text-align: center; }

#txt-white {
  color: white;
  font-weight: 200;
}

.list {
  margin: .5em 0 .3em 0 !important;
  padding-left: .7em !important;

  &-head {
    border-left: solid #06a0b5 2px;
  }
}

@media only screen and (min-width: 435px) {
  .center { 
    text-align: left;
  }
}

@media only screen and (max-width: 768px) {
  .footer {
    &-mail {
      padding: 10px 0;
      color: white;
      font-weight: 200;
      font-size: 1.5em;
    }

    &-social {
      width: 2.3em;
      height: 2.3em;
    }
  }
}