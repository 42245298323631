.expand-link {
    display: inline-block;

    .flag-color {
        display: none;
    }

    &:hover .flag-black,
    &:active .flag-black{
        display: none;
    }

    &:hover .flag-color,
    &:active .flag-color {
        display: block;
    }

}

a {
    &:visited,
    &:link {
        text-decoration: none;
    }
}