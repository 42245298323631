@import './../../../vars.scss';

.container-contact {
  .container-form {
    z-index: 3;
    position: relative;
    background-color: $back-contact;
    max-width: 1048px;
    width: calc(100% - 4em);
    margin: auto;
    border-radius: 20px 20px 0 0;
    padding: 2em;
    -webkit-box-shadow: -14px -14px 19px -9px rgba(70, 70, 70, 0.3), 14px -14px 19px -9px rgba(70, 70, 70, 0.3); 
    box-shadow: -14px -14px 19px -9px rgba(70, 70, 70, 0.3), 14px -14px 19px -9px rgba(70, 70, 70, 0.3);
  }

  .container-contact-info {
    display: grid;
    grid-template-columns: calc(50% - 3em) 50%;
    grid-column-gap: 3em;

    .MuiInput-underline:after {
      border-bottom: 2px solid $line-form;
    }
    .MuiInput-underline:hover:not(.Mui-disabled):before {
      border-bottom: 2px solid $line-form;
    }
    .MuiInput-underline:before {
      border-bottom: 1px solid $line-form;
    }
    .MuiFormLabel-root, .MuiFormLabel-root.Mui-focused {
      color: white;
      font-weight: 300;
    }
    .MuiInputBase-input {
      padding: 6px 0 15px;
    }
    input, textarea {
      color: white;
      font-weight: 300;
    }

    .title-contact {
      color: $title-contact;
      text-align: left;
      font-size: 1.5em;
    }

    .talkUs {
      font-weight: 200;
      font-size: 1.9em;
      letter-spacing: 2px;
      line-height: 1em;
      color: white;
    }

    .center {
      text-align: left;
      padding-top: 14em;
    }

    .btn-send {
      color: white;
      font-size: 1.3em;
      font-weight: 100;
      border: 1px solid #1ffff9 !important;
      padding: 0.1em 2.3em;
      border-radius: 10px;
      transition: all 250ms;
    }

    .btn-send:hover {
      border: 1px solid white !important;
      background-color: white;
      color: #06a0b5;
    }
  }

  .lower {
    text-transform: lowercase !important;
  }

  .container-footer {
    padding-top: 26em;
    margin-top: -26em;
    background-color: $back-contact;
    z-index: 1;
    position: relative;
  }
}

.MuiSnackbar-root .MuiTypography-root {
  background-color: white;
  color: $blue-dark-beluga;
}

@media only screen and (min-width: 426px) {
  .container-contact {
    .container-contact-info {
      .center {
        padding-top: 11em;
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .container-contact {
    .container-contact-info {
      .center {
        padding-top: 9.5em;
      }
    }
  }
}

@media only screen and (min-width: 1920px) {
  .container-contact {
    .container-contact-info {
      .center {
        padding-top: 8em;
      }
    }
  }
}